import analytics from '@capturi/analytics'
import { useFeatureFlags } from '@capturi/feature-flags'
import { getErrorMessage } from '@capturi/request'
import { useOrganization } from '@capturi/stores'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React, { ChangeEvent, useState } from 'react'
import isEmailValid from 'utils/isEmailValid'
import {
  UiRole,
  toServerRole,
  useUserActions,
} from '../../hooks/useUserActions'

type Props = BaseModalComponentProps

const CreateUserModal: React.FC<Props> = ({ onClose }) => {
  const { enableText } = useFeatureFlags()
  const { uiLanguage } = useOrganization()
  const toast = useToast()
  const { createUser } = useUserActions()
  const nameRef = React.useRef<HTMLInputElement>(null)

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [role, setRole] = useState<UiRole>('teamlead')

  const [shouldSendEmail, setShouldSendEmail] = useState(true)

  const [inProgress, setInProgress] = useState(false)

  React.useEffect(() => {
    analytics.modalview('CreateUserModal')
    analytics.event('organization_users_openCreate')
  }, [])

  const submit = async (): Promise<void> => {
    setInProgress(true)

    try {
      const sendInvite = shouldSendEmail && isEmailValid(email)
      const { role: serverRole, permissions } = toServerRole(role)

      await createUser({
        name,
        email,
        sendInvite,
        role: serverRole,
        permissions: { ...permissions, text: enableText },
        language: uiLanguage,
      })
      analytics.event('organization_users_create', {
        shouldSendInvite: sendInvite,
      })
      onClose()
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      toast({
        title: t`This did not go as we expected`,
        description: errorMsg,
        status: 'error',
      })
    } finally {
      setInProgress(false)
    }
  }

  return (
    <Modal
      isOpen
      onClose={inProgress ? noop : onClose}
      initialFocusRef={nameRef}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Trans>Add new user</Trans>
          </ModalHeader>
          {!inProgress && <ModalCloseButton />}
          <ModalBody>
            <FormControl>
              <FormLabel htmlFor="add-user-name">
                <Trans>Name</Trans>
              </FormLabel>
              <Input
                id="add-user-name"
                isDisabled={inProgress}
                ref={nameRef}
                key="name"
                placeholder={t`Name`}
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  setName(e.target.value)
                }
                value={name}
              />
              <FormLabel htmlFor="role" mt="3">
                <Trans>Role</Trans>
              </FormLabel>
              <Select
                isDisabled={inProgress}
                id="role"
                key="role"
                onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                  setRole(e.target.value as UiRole)
                }}
                value={role}
              >
                <option value={'teamlead' as UiRole}>{t`Team lead`}</option>
                <option value={'administrator' as UiRole}>{t`Admin`}</option>
                <option value={'owner' as UiRole}>{t`Owner`}</option>
              </Select>

              <FormLabel htmlFor="add-user-email" mt="3">
                <Trans>E-mail</Trans>
              </FormLabel>
              <Input
                isDisabled={inProgress}
                id="add-user-email"
                key="email"
                placeholder={t`E-mail`}
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  setEmail(e.target.value)
                }
                value={email}
              />
              <Checkbox
                m="3"
                isChecked={shouldSendEmail}
                onChange={(e) => setShouldSendEmail(e.target.checked)}
                isDisabled={!isEmailValid(email) || inProgress}
                title={
                  isEmailValid(email)
                    ? t`Send e-mail invitation`
                    : t`Enter a valid e-mail address`
                }
              >
                <Trans>Send invite e-mail</Trans>
              </Checkbox>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" spacing={4}>
              <Button secondary onClick={onClose} isDisabled={inProgress}>
                <Trans>Cancel</Trans>
              </Button>
              <Button primary isLoading={inProgress} onClick={submit}>
                <Trans>Create</Trans>
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CreateUserModal
